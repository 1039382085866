body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.map-container {
  height: 5vh;
  }
  .mapController {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mapController > div:nth-child(2){
    margin-top: 20px;
    font-size: 30px !important;
    cursor: pointer;
  }

  .leaflet-container {
    width: 100vw;
    height: 100vh;
  }
  
  










textArea{
  resize: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-box, .register-box {
  margin : 3% auto !important;
}

.content {
  padding: 20px;
}

.header {
  padding: 10px;
  background: #edf2f4;
  border-bottom: 1px solid #999;
}
.header a {
  color: #0072ff;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 5px;
}
.header a:hover {
  color: #8a0f53;
}
.header small {
  color: #666;
}
.header .active {
  color: #2c7613;
}
.swal2-icon {
  width: 8em !important;
  height: 8em !important;
}
.swal2-title {
  font-size : 2.875em !important;
}
.swal2-content{
  font-size : 1.5em !important;
}
.swal2-cancel {
  margin-right: 5px !important;
}
.swal2-popup {
  width: 478px !important;
}
